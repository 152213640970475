<template>
  <div v-if="isDataLoaded">
    <br />

    <revenue :payouttotal="data.countData.currentMonthPayout"/>

    <br />

    <v-row>
       <v-col cols="12" sm="6">
        <event-sales />
      </v-col>
      <v-col cols="12" sm="6" >
        <vetting-events @updateVettingList="getEvents()" :items="data.events" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8">
        <transactions />
      </v-col>

      <v-col cols="12" sm="4">
        <Card title="Number of Promoters" color="info" :value="data.countData.promoterCount" @click="goToPromoter()" />
        <!-- <Card title="Number of Organisers" color="success" :value="53" @click="onAction()" /> -->
        <Card title="Number of Performers" color="error" :value="data.countData.performerCount" @click="goToPerformers()" />

        <Card title="Number of Active Events" color="success" :value="data.countData.activeEventsCount" @click="goToEvents()" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <sales />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PromoterEventService } from '@services';

import Card from './card';
import Revenue from './revenue';
import EventSales from './event-sales';
import Transactions from './transactions';
import VettingEvents from './vetting-events';
import Sales from './sales.vue'

export default {
  components: {
    Card,
    Revenue,
    EventSales,
    Transactions,
    VettingEvents,
    Sales
  },
  data() {
    return {
      isDataLoaded: false,
      picker: new Date().toISOString().substr(0, 10),
      data: {
        events: [],
        countData: {},
      },
    };
  },
  created() {
    this.$store.set('app/state', {});
    this.getEvents();
  },

  methods: {
    goToPromoter() {
     this.$next({ name: 'admin.promoters' });
    },
    goToPerformers() {
      this.$next({ name: 'admin.performers' });
    },
    goToEvents() {
      this.$next({ name: 'admin.events' });
      this.$root.$emit('show-active-events')
    },
    async getEvents() {
      const response = await PromoterEventService.getDashboardData();
      if (response) {
        this.data.events = response.events
        this.data.countData = response;
      }

      this.isDataLoaded = true;
    },
  },
};
</script>

<style lang="scss">
.v-data-table-small {
  td,
  th {
    font-size: 0.75rem !important;
    height: 32px !important;
  }
}
</style>
