<template>
  <v-card>
    <v-card-title> Revenue </v-card-title>

    <v-card-subtitle>
      This Month: <b class="primary--text"> {{ payouttotal | currency }}</b>
    </v-card-subtitle>

    <v-card-text class="px-0">
      <v-tabs v-model="tab" right show-arrows height="32" @change="getEvents()">
        <v-tab class="text-caption px-2">Last 24 hours</v-tab>
        <v-tab class="text-caption px-2">Last 7 days</v-tab>
        <v-tab class="text-caption px-2">Last 30 days</v-tab>
        <v-tab class="text-caption px-2">Last 6 months</v-tab>
      </v-tabs>

      <div v-show="noData" class="py-16 text-sm-h5 w-100 text-center">
        Currently there are no data for sales analytics given the time ranges
      </div>

      <v-responsive v-show="!noData" :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4">
        <div id="chart_div" class="h-100"></div>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';

export default {
  props: {
    payouttotal: Number,
  },
  data() {
    return {
      tab: 1,
      hAxisTicks: [],
      noData: false,
      isLoaded: false,
      periods: ['24hours', '7days', '28days', '6month'],
    };
  },
  async mounted() {
    if (!window.google?.charts) {
      await this.$loadScript('https://www.gstatic.com/charts/loader.js');
      window.google.charts.load('47', {
        packages: ['corechart', 'bar']
      });
      window.google.charts.setOnLoadCallback(this.getEvents);
      return
    }
    this.getEvents()
  },
  methods: {
    async getEvents() {
      this.isLoaded = false;
      this.noData = false;

      const response = await PromoterEventService.getAdminDashboardCharts({ time: this.getKeys() });
      if (response) {
        this.hAxisTicks = [];
        if (response && response.length) {
          this.data = response.map((item, index) => {
            item[0] = {
              v: index,
              f: item[0].toString(),
            };

            this.hAxisTicks.push(item[0]);

            return item;
          });
          this.generateChart();
        } else {
          this.noData = true;
          this.chart?.clearChart();
        }
      }

      this.isLoaded = true;
    },
    getLabel() {
      switch (this.tab) {
        case 0:
          return 'Hours';
        case 1:
          return 'Days';
        case 2:
          return 'Days';
        case 3:
          return 'Months';
      }
    },
    getKeys() {
      switch (this.tab) {
        case 0:
          return '24hours';
        case 1:
          return '7days';
        case 2:
          return '28days';
        case 3:
          return '6month';
      }
    },
    generateChart() {
      var data = window.google.visualization.arrayToDataTable([['Month', 'Revenue'], ...this.data]);
      var options = {
        // title: 'Monthly Coffee Production by Country',
        vAxis: { title: 'Revenue (in pound)' },
        hAxis: { title: this.getLabel(), ticks: this.hAxisTicks },
        colors: ['#A6CEE3'],
      };

      this.chart = new window.google.visualization.ColumnChart(document.getElementById('chart_div'));
      this.chart.draw(data, options);
    },
    clearChart() {
      this.chart?.clearChart();
    },
  },
};
</script>
