<template>
  <v-card class="h-100">
    <v-card-title>
      <v-row justify="space-between">
        <v-col cols="12" sm="6">
          Event sales
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select :items="keys" v-model="query.search" @change="filterChartData()" label="Type" outlined dense></v-select>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="px-0">
      <v-responsive :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4">
        <div id="event_sales_chart" class="h-100"></div>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';
// import moment from 'moment';

export default {
  data() {
    return {
      tab: 0,
      toggle: 0,
      query: {
        search: 'All'
      },
      keys: []
    };
  },
  async mounted() {
    if (!window.google?.charts) {
      await this.$loadScript('https://www.gstatic.com/charts/loader.js');
      window.google.charts.load('47', {
        packages: ['corechart']
      });
      window.google.charts.setOnLoadCallback(this.getEvents);
      return
    }
    this.getEvents()
  },
  methods: {
    async getEvents() {
      const response = await PromoterEventService.getAdminDashboardSalesCharts(this.query);
      if (response) {
        this.data = response.chartData;
        this.keys = response.keys;
        this.keys.unshift('All')
        this.keys.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        this.generateChart();
      }
    },
    filterChartData() {
      this.getEvents();
    },
    generateChart() {
      // var data = window.google.visualization.arrayToDataTable([['Month', 'Revenue'], ...this.data]);
      var data = window.google.visualization.arrayToDataTable([['Month', 'Revenue'], ...this.data]);
      let total = 0;
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];
        total += element[1];
      }
      var options = {
        title: `Sales by ${(this.query.search === 'All' ? 'Event Category' : this.query.search)} £${total}`,
        pieHole: 0.5,
        pieSliceText: 'none',
        colors: ['#A6CEE3', '#23527C', '#3D9E41', '#E2B93B', '#AA1F17'],
      };

      var chart = new window.google.visualization.PieChart(document.getElementById('event_sales_chart'));
      chart.draw(data, options);
    },
  },
};
</script>
