<template>
  <v-card>
    <v-card-title class="d-flex pl-4 pr-4 pb-0">
      <v-row>
        <v-col cols="12" sm="4" xl="3" class="text-left text-sm-left">
          <v-row>
            <v-col cols="12">
              Payouts
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="12" lg="4">
          <v-text-field
            outlined
            dense
            hide-details
            clearable
            name="eventId"
            label="Search By Event Id"
            v-model="query.eventId"
            placeholder="e.g. 6139e7fb23b5f009220eabd1"
          />
        </v-col>
        <v-col cols="12" lg="4">
          <v-select
            v-model="payoutStatus"
            :items="payoutStatuses"
            :item-text="'text'"
            :item-value="'value'"
            label="Payout Status"
            append-icon="mdi-magnify"
            dense
            clearable
            outlined
            v-on:change="filterPayoutStatus"
          ></v-select>
        </v-col>
        
      </v-row>
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" :search="payoutStatus" :custom-filter="filterPayoutStatus">
      <template v-slot:[`item.name`]="{ item }">
        {{ item.organiser.name}}
      </template>
       <template v-slot:[`item.amount`]="{ item }">
        {{ item.promoterPayout.amount | currency}}
      </template>
       <template v-slot:[`item.status`]="{ item }">
        {{ item.promoterPayout.status | capitalize}}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.promoterPayout.createdAt | date }}
      </template>
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ item.startsAt | dateTime }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      headers: [
        {
          text: 'Promoter Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Event ID',
          sortable: false,
          value: '_id',
        },
        {
          text: 'Event Name',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Event Date ', value: 'startsAt', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Date & Time', value: 'date', sortable: false },
      ],
      items: [],
      query: {
        limit: 'all'
      },
      payoutStatuses: [
        { text: 'All', value: 'all' },
        { text: 'Pending', value: 'pending' },
        { text: 'Paid', value: 'paid' },
      ],
      payoutStatus: '',
    };
  },
   async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await PromoterEventService.getPayoutList(this.query);
      if (response) {
        this.items = response;
      }
    },
    filterPayoutStatus(value, search, item) {
      if(search !== undefined) {
        search = search.toString().toLowerCase();
        if(search === 'all') {
          search = '';
        }
      }
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          item.promoterPayout.status.toString().indexOf(search) !== -1
    }
  },
  watch: {
    'query.eventId': async function() {
      this.getData();
    },
  }
};
</script>
