<template>
  <v-card>
    <v-card-title>
      Vetting Events
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.promoter`]="{ item }">
        {{ item.organiser.name}}
      </template>
       <template v-slot:[`item.venue`]="{ item }">
        {{ item.venue.name}}
      </template>
       <template v-slot:[`item.genre`]="{ item }">
        {{ item.genre.name}}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.startsAt | date }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="item.status === 'vetting'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="approveEvents(item)">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </template>
            <span>Approve Event</span>
          </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';

export default {
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Actions', value: 'actions', sortable: false, width: '100px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        { text: 'Promoter', value: 'promoter', sortable: false },
        { text: 'Category', value: 'genre', sortable: false },
        { text: 'Venue', value: 'venue', sortable: false },
        { text: 'Date & Time', value: 'date', sortable: false },
      ],
    };
  },
  methods: {
    async approveEvents(item) {
      this.$confirm({
        title: `Are you sure you want to Approve the event?`,
        resolve: async () => {
          await PromoterEventService.sendApprovedMail({ eventId: item._id, organiserId: item.organiser });
          this.$emit('updateVettingList');
        },
      });
    }
  }
};
</script>
