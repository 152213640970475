<template>
  <v-card>
    <v-card-title>
      Payouts

      <v-spacer />
      <v-btn text @click="seeAllTrasactions()">See All</v-btn>
    </v-card-title>

    <v-data-table class="v-data-table-small" :headers="headers" :items="items" hide-default-footer>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.organiser.name}}
      </template>
       <template v-slot:[`item.amount`]="{ item }">
        {{ item.promoterPayout.amount | currency}}
      </template>
       <template v-slot:[`item.status`]="{ item }">
        {{ item.promoterPayout.status | capitalize}}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.promoterPayout.createdAt | date }}
      </template>
      <template v-slot:[`item.startsAt`]="{ item }">
        {{ item.startsAt | dateTime }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      headers: [
        {
          text: 'Promoter Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '150px'
        },
        {
          text: 'Event ID',
          sortable: false,
          value: '_id',
          width: '100px'
        },
        {
          text: 'Event Name',
          align: 'start',
          sortable: false,
          value: 'title',
          width: '150px'
        },
        { text: 'Event Date ', value: 'startsAt', sortable: false, width: '200px' },
        { text: 'Amount', value: 'amount', sortable: false, width: '100px' },
        { text: 'Status', value: 'status', sortable: false , width: '100px'},
        { text: 'Date & Time', value: 'date', sortable: false, width: '100px' },
      ],
      items: [],
    };
  },
   async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await PromoterEventService.getPayoutList();
      if (response) {
        this.items = response;
      }
    },
    seeAllTrasactions() {
      this.$next({ name: 'admin.dashboard.transactions' });
    }
  }
};
</script>
