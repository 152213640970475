<template>
  <v-card class="mt-4">
    <v-card-subtitle>{{ title }} </v-card-subtitle>
    <v-card-title>
      <span :class="`${color}--text`">
        {{ value }}
      </span>

      <v-spacer />

      <v-btn icon @click="$emit('click')">
        <v-icon>mdi-arrow-right-thin-circle-outline</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: Number, required: true },
    color: { type: String, required: true },
  },
};
</script>
